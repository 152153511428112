.ContactOrdersWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: red; */
  background-color: #232B36;
  border-right: 1px solid #000;
}

.noContactSelected {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  /* background-color: #303C4B; */
  background-color: #232B36;
  color: #68718c;
  font-size: 20px;
  border-right: 1px solid #000;
}