.Login {
  background: #0F141A;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.Login .LoginCard {
  background: white;
  min-width: 550px;
  min-height: 450px;
  padding: 10px;
  padding-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Login .LoginCard .LoginCardLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.LoginCard .LoginCardLoading .LoginLoaderContainer {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.Login .LoginCard .LoginCardBody {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Login .LoginCard .LoginCardBody .logo{
  height: 100px;
}

.Login .LoginCard h2, .Login .LoginCard h1 {
  font-weight: 400;
}

.Login .LoginCard h2 {
  letter-spacing: 2px;
}

.Login .LoginCard h1 {
  letter-spacing: .5px;
}

.LoginCard .LoginCardFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.LoginCard .LoginCardFooter button {
  height: 46px;
  padding: 0;
  border: 1px solid #000;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  width: 240px;
  letter-spacing: .1px;
  transition: opacity .2s;
}

.LoginCard .LoginCardFooter button:hover {
  opacity: 0.9;
}

.LoginCard .LoginCardFooter .google-btn {
  border-color: #4885ed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.LoginCard .LoginCardFooter .facebook-btn {
  background: #3b5998;
  border-color: #3b5998;
  display: flex;
  flex-direction: row;
}

.LoginCard .LoginCardFooter .facebook-btn .facebookText {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.LoginCard .LoginCardFooter .facebook-btn .facebookImageContainer {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.LoginCard .LoginCardFooter .google-btn img {
  height: 20px;
}
.LoginCard .LoginCardFooter .facebook-btn img {
  background: white;
  border-radius: 3px;
}
.LoginCard .LoginCardFooter .google-btn span:last-child {
  background: #4885ed;
}
.LoginCard .LoginCardFooter button span {
  height: 45px;
  color: white;
}

.LoginCard .LoginCardFooter button span:first-child {
  width: 50px;
  height: 45px;
}

.LoginCardFooter .google-btn .googleLogoContainer {
  display: flex;
  width: 19%;
  justify-content: center;
  align-items: center;
}

.LoginCardFooter .google-btn .googleSignin {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricingRedirectionForLite {
  color: #0645ad;
  cursor: pointer;
}
