$sectionSeperator: 1.5rem;

.price-title {
  align-self: flex-start;
  margin-bottom: $sectionSeperator;
  margin-top: 0px;
}

.input--container {
  margin-bottom: $sectionSeperator;
  font-size: 1.2rem;
  color: grey;
  width: 400px;
  max-width: 100%;
}

.price-button-container {
  text-align: right;
}

.price-button {
  border: none;
  background-color: transparent;
  color: grey;
  font-size: 0.9rem;
}

.price-button--submit {
  margin-left: 15px;
  font-weight: bold;
  color: #59C18F;
}

.price-icon {
  color: black;
}
