.inquriesContainer {
  padding: 0px 22px;
  background-color: #191f26;
  margin-bottom: 10px;

  .text-line-through {
    text-decoration: line-through;
  }

  .inquriesWrapper {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    padding: 12px;

    .imageContainer {
      margin-right: 15px;
      position: relative;

      .image {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        z-index: 2;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
      }

      .smallImageContainer {
        background-color: transparent;
        border: none;
      }
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      flex: 1;

      .productTitle {
        color: white;
        margin-bottom: 7px;
        font-size: 18px;
        max-height: 22px;
        overflow: hidden;
        align-self: flex-start;
      }

      .set-price {
        position: relative;
        border: none;
        color: white;
        background-color: #4d89f5;
        align-self: flex-start;
        padding: 4px 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        font-size: 13px;

        .set-price-loader {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255,255,255,0.7);
          width: 100%;
          border-radius: inherit;
        }
      }

      .markOOS {
        border: 1px solid #fc5959;
        padding: 4px 10px;
        border-radius: 20px;
        color: white;
        background-color: transparent;
        font-size: 13px;
        align-self: flex-start;

        &:hover {
          background-color: #161c29;
        }
      }

      .OOS {
        background-color: #fc5959;

        &:hover {
          background-color: #e14d4d;
          border: 1px solid #e14d4d;
        }
      }

      .nameContainer {
        display: flex;
        align-self: flex-start;
        margin-bottom: 3px;

        .new {
          margin: 0px 8px 6px 0px;
          color: black;
          background-color: white;
          padding: 2px 3px;
          border-radius: 2px;
          align-self: center;
          font-size: 10px;
        }
      }

      .price {
        color: white;
        margin-bottom: 10px;
        align-self: flex-start;
      }

      .description {
        color: white;
        word-break: break-all;
        margin-bottom: 10px;
        align-self: flex-start;
      }

      .deletedByVisitorContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3px;

        .crossIcon {
          width: 19px;
          height: 19px;
          margin-left: -5px;
        }

        .deletedByVisitor {
          color: #bf392c;
          font-size: 14px;
          margin-bottom: 2px;
        }
      }
    }

    .modalContainer {
      background-color: red;

      .imageContainer {
        width: 500px;
        height: 500px;
        border-radius: 10px;
        border: 1px solid white;
      }
    }

    .checkboxContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 20px;
      margin-right: 15px;
    }

    .showCheckbox {
      display: block;
    }

    .hideCheckbox {
      display: none;
    }
  }

  &:hover .inquriesWrapper .checkboxContainer {
    display: block;
  }
}
