.checkout-section-modal {
  &.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button-plain {
      border: none;
      background-color: transparent;
      font-size: 0.9rem;
    }

    .button-submit {
      color: #59c18f;
      font-weight: 700;
    }
  }

  &.checkout-text--title {
    margin: 0px;
  }

  &.checkout-text-description {
    color: #999;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  &.checkout-text-area {
    border: 1px solid #ccc;
    margin-bottom: 2em;
    height: 80px;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 4px;
    padding: 10px;
  }
}