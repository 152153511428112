.InquriesHeadaerContainer {
  align-self: flex-start;
  width: 100%;
  padding: 10px;
  background-color: #0F141A;
  color: white;
}

.InquriesHeadaerContainer .header {
  display: flex;
  flex-direction: row;
}

.InquriesHeadaerContainer .extraOptionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10%;
}

.optionContainer .optionsButtonContainer {
  background-color: transparent;
  border: none;
}

.iconContainer .icon {
  margin-bottom: 10px;
}

.optionsButtonContainer .icon {
  width: 35px;
  height: 35px;
  fill: #FFFFFF;
}

.optionsButtonContainer:hover .contactIcon {
  fill: rgb(141, 240, 193);
}
.optionsButtonContainer:hover .contactTitle {
  color: rgb(141, 240, 193);
}


.optionsButtonContainer:hover .sendIcon {
  fill: rgb(141, 240, 193);
}
.optionsButtonContainer:hover .sendTitle {
  color: rgb(141, 240, 193);
}


.optionsButtonContainer:hover .deleteIcon {
  fill: rgb(141, 240, 193);
}
.optionsButtonContainer:hover .deleteTitle {
  color: rgb(141, 240, 193);
}

.optionsButtonContainer .optionsTitleContainer {
  color: white;
  font-size: 13px;
  text-transform: uppercase;
}

.verticalFlex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.verticalFlex:last-of-type {
  margin-bottom: 0px;
}

.popupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.4);
  margin: 10px;
}

.popupContainer .singleRow {
  color: black;
  font-size: 16px;
  padding: 13px 20px;
  border-bottom: 1px solid #d7d8de;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.popupContainer .disabledRow {
  background-color: #e9e9e9;
  color: #939393;
  font-size: 16px;
  padding: 13px 20px;
  border-bottom: 1px solid #d7d8de;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.popupContainer .singleRow:hover {
  background-color: #efefef;
}

.singleRow .iconContainer {
  margin-right: 14px;
}

.disabledRow .iconContainer {
  margin-right: 14px;
}

.singleRow .popupTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.iconContainer .popupIcon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}

.extraOptionsContainer .inquiryDeleteModal {
  background-color: white;
}

.inquiryDeleteModal .header {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

.inquiryDeleteModal .title {
  margin-bottom: 25px;
  font-size: 16px;
}

.inquiryDeleteModal .buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.inquiryDeleteModal .buttonContainer .deleteButton {
  background-color: transparent;
  font-size: 18px;
  color: #f06b6b;
}

.inquiryDeleteModal .buttonContainer .deleteButton:hover {
  font-weight: bold;
  cursor: pointer;
}

.inquiryDeleteModal .buttonContainer .cancelButton {
  background-color: transparent;
  font-size: 18px;
  color: #858585
}

#toast-container > .loaderImage {
  /*background-image: url('./../Assets/Media/toastrLoader.svg')!important;*/
  background-color: #51A351
}

.loaderImage .toast-message {
  margin-left: 6px;
}
