.default-profile-logo-container {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid black;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-logo {
  border-radius: 4px;
}

.profile--details {
  margin-bottom: 5px;
}
