.addTagInput {
  display: flex;
  flex-direction: row;
  padding: 10px 10px 12px 10px;
  background-color: #f3f9f4;
  position: relative;

  .infoButton {
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .infoButtonIcon {
      width: 25px;
      height: 25px;
    }
  }

  .inputContainer {
    background-color: transparent;
    display: flex;
    flex: 1;

    .input {
      font-size: 15px;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 2px 4px rgba(203, 203, 203, 0.5);
      padding: 0 20px;
    }

    .input::-webkit-input-placeholder {
      color: #7D7D7D;
    }
  }

  .submitButton {
    cursor: pointer;
    margin: 0 10px;
    background-color: #7cd3a3;
    border-radius: 20px;
    justify-content: center;
    padding: 7px 20px 5px 20px;
    box-shadow: 0 2px 2px #65be8d;

    .submitButtonWrapper {
      position: relative;

      .submitButtonTagIcon {
        width: 20px;
        height: 20px;
      }

      .submitButtonPlusIcon {
        background-color: #7cd3a3;
        position: absolute;
        bottom: 3px;
        right: 2px;
        padding: 2px;
        border: 1px solid #fff;
        border-radius: 20px;
        width: 5px;
        height: 5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .submitButtonPlusIconText {
        margin-top: 1.5px;
        margin-left: 1px;
        font-size: 8px;
        color: #fff;
      }
    }

    &:hover {
      background-color: #5DB082;
      box-shadow: 0 2px 2px #5DB082;

      .submitButtonPlusIcon {
        background-color: #5DB082;
      }
    }
  }
}

.tipContainer {
  position: absolute;
  left: 10px;
  bottom: 72px;
  width: 250px;
  background-color: #fff;
  padding: 0 20px 15px 20px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);

  .tipHeader {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tipHeaderIcon {
      width: 25px;
      height: 25px;
    }

    .tipHeaderText {
      flex: 1;
      margin-left: 10px;
      font-size: 18px;
      color: #000;
      font-weight: 700;
    }

    .tipCloseButton {
      margin-right: -15px;
      cursor: pointer;
      padding: 5px;

      .tipCloseIcon {
        width: 30px;
        height: 30px;
      }

      &:hover {
        .tipCloseIcon {
          fill: #000;
        }
      }
    }
  }

  .tipContent {
    margin-top: -5px;

    .tipContentText {
      font-size: 14px;
      color: #000;
    }
  }
}
