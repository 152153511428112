.company-profile-container {

  .company-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .placeholder-logo {
      border: none;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #323b47;
    }

    .logo-container {
      width: 100px;
      position: relative;
      border-radius: 10px;

      & > img {
        width: 100%;
      }
    }
  }

  .address-container {
    font-family: inherit;
    margin: 0px;
  }
}
