.inquriesListContainer {
  overflow: auto;
  max-height: '100%';
}

.inquriesListContainer .inquiry-column-container {
  display: flex;
  flex-direction: column;
}

.inquriesListContainer .inquiry-order-info {
  color: white;
  padding: 10px;
  margin: 10px;
  background-color: #191f26;
  align-self: flex-start;
  border-radius: 5px;
}

.inquiry-order-info .inquiry-order-seperator {
  margin-bottom: 5px;
}

.inquiry-order-info .order-text-color {
  color: #667284;
}

.inquiry-order-info .order-id {
  color: #59C18F;
  margin-left: 15px;
}

.inquiry-order-info .inquiry-order--text {
  font-size: 0.8em;
  font-weight: 400;
}

.loaderContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}