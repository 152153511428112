.productTagsSelector {
  .tagsTitle {
    margin-top: 5px;
    color: #989898;
    font-size: 14px;
    font-weight: 800;
  }

  .unselectedTagsScrollContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    .unselectedTag {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 15px;
      border: 1px dashed #989898;
      white-space: nowrap;
      display: flex;
      flex-direction: row;

      .unselectedTagText {
        color: #989898;
        font-size: 14px;
        font-weight: 600;
      }

      .plus {
        display: none;
        background-color: rgb(42, 160, 71);
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        margin-left: 5px;
        width: 17px;
        height: 17px;
        text-align: center;
        margin-right: -4px;
      }

      &:hover {
        background-color: #333;
        border: 1px solid #333;

        .unselectedTagText {
          color: #fff;
        }

        .plus {
          display: block;
        }
      }
    }

    .productTagsSelectorHeader {
      //width: 30px;
    }

    .productTagsSelectorFooter {
      width: 30px;
    }
  }

  .bulkSelectedTagsLoader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    min-height: 40px;
  }

  .selectedTagsContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    min-height: 35px;

    .selectedTagsScrollContainer {
      display: flex;
      flex-direction: row;
      margin-right: 40px;

      &::-webkit-scrollbar {
        display: none;
      }

      .selectedTag {
        padding: 5px 15px;
        border-radius: 15px;
        background-color: #333;
        align-self: center;
        white-space: nowrap;

        .selectedTagText {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .productTagsSelectorHeader {
        //width: 30px;
      }

      .productTagsSelectorFooter {
        width: 65px;
      }
    }

    .tagButton {
      position: absolute;
      right: 20px;
      background-color: rgb(217, 218, 220);
      box-shadow: -5px 0px 20px 2px rgba(217, 218, 220, 0.5);
    }
  }

  .addTagsContainer {
    margin-left: 30px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .removeMargin {
    margin-left: 0px !important;
  }

  .tagButton {
    cursor: pointer;
    overflow: visible;
    background-color: rgba(255, 255, 255, 0.4);
    border: solid #000;
    border-width: 1px 1px 3px;
    padding: 6px 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      background-color: #fff;
    }

    .tagButtonIcon {
      width: 16px;
      height: 16px;
    }

    .tagButtonText {
      margin-left: 5px;
      color: #000;
      font-size: 15px;
    }
  }

  .productTagsSelectorSeparator {
    width: 10px;
  }
}
