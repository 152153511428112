.product-demo--text {
  margin-bottom: 10px;
}

.product-demo--button {
  border-radius: 5px;
  background-color: #2da56b;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
}
