.company-address--container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &.error {
    color: red;

    .error-container {
      margin-top: 5px;
    }
  }
}

.company-address--label {
  font-size: 0.9rem;
}

.company-address--input {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  flex-grow: 1;
  width: 100%;
  font-size: 1rem;
  padding: 10px 0px;

  &:hover, &:focus {
    border-bottom: 1px solid black;
  }
}

.edit-address {
  &.button-container {
    text-align: right;
    .button-plain {
      border: none;
      background-color: transparent;
      font-size: 0.9rem;
      color: #59c18f;
      font-weight: bold;
    }
  }
}
