.newInquriesAvailableHeader {
  background-color:'transparent';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5px 15px 5px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.newInquriesAvailableHeader .newInquriesContainer {
  background-color: #FFF3C3;
  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 8px 17px;
  font-size: 14px;
}

.newInquriesAvailableHeader .newInquriesContainer:hover {
  cursor: pointer;
  background-color: #F5E296
}

.newInquriesContainer .textContainer {
  color: #71601E;
  margin: 3px 52px 0px 0px;
}

.newInquriesContainer .refreshButton {
  padding: 2px 0px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
}

.refreshButton .refreshIcon {
  border: none;
  height: 14px;
  width: 14px;
  margin: 2px 4px 0px 0px;
}