#NonPlatinumUserDailog {
  position: absolute;
  max-width: 370px;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 11;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding: 20px;
  justify-content: flex-start;
  align-items: center;

  .title {
    font-weight: 300;
    margin-bottom: 33px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 18px;
    color: rgba(0,0,0,0.8);
  }

  .contactSupportLiteDialogue {
    color: rgba(0,0,0,0.8);
    padding: 8px 12px;
    border-radius: 3px;
    text-decoration: none;
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    min-width: 100px;
  }
}
