.catalogue-section {

  .text-template-share {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
    background: transparent;
    color: white;
    font-size: 0.9rem;
  }

  .text-template-share-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.catalogue-section-modal {
  &.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button-plain {
      border: none;
      background-color: transparent;
      font-size: 0.9rem;
    }

    .button-reset {
      color: #fc5959;
    }

    .button-submit {
      color: #59c18f;
      font-weight: 700;
    }
  }

  &.catalogue-share-text--title {
    margin: 0px;
  }

  &.catalogue-share-text-description {
    color: #999;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  &.catalogue-text-area {
    border: 1px solid #ccc;
    margin-bottom: 2em;
    height: 80px;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 4px;
    padding: 10px;
  }
}
