.bulkInventoryContainer {
  padding: 30px 0px;
  color: black;

  .rowHeading {
    font-size: 15px;
    font-weight: 600;
  }

  .stockaAvailabilityContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;

    .header {
      margin-bottom: 15px;
    }

    .options {
      display: flex;
      flex-direction: row;

      .outOfStockWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #b71c1c;
        color: #b71c1c;
        margin-right: 10px;
        border-radius: 4px;
        padding: 2px;

        &:hover {
          cursor: pointer;
        }

        .outOfStockContainer {
          font-size: 13px;
          text-transform: uppercase;
          padding: 8px 13px;
        }

        .selectedOOS {
          background-color: #b71c1c;
          color: white;
          border-radius: 4px;
        }
      }

      .availableContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #4da47a;
        color: #4da47a;
        margin-right: 10px;
        border-radius: 4px;
        padding: 2px;

        &:hover {
          cursor: pointer;
        }
      }

      .stockAvailableContainer {
        font-size: 15px;
        text-transform: uppercase;
        padding: 0px 13px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .availableSelected {
        background-color: #4da47a;
        color: white;
        border-radius: 4px;
      }
    }
  }

  .autoReduceInventory {
    margin-bottom: 28px;

    .header {
      margin-bottom: 15px;
    }

    .actionButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;


      .buttonStyle {
        border: 1px solid #4da47a;
        padding: 3px;
        margin-right: 16px;
        border-radius: 4px;
        font-size: 15px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          cursor: pointer;
        }

        .autoReduce {
          padding: 8px 10px;
          border-radius: 4px;
        }

        .selected {
          background-color: #4da47a;
          color: white;
        }
      }
    }
  }

  .availableQuantityContainer {
    margin-bottom: 28px;

    .header {
      margin-bottom: 15px;
    }

    .actionButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .buttonStyle {
        border: 1px solid #cecece;
        margin-right: 15px;
        padding: 3px;
        border-radius: 4px;
        text-align: center;
        font-size: 15px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          cursor: pointer;
        }

        .inventoryContainer {
          padding: 5px 7px;
          border-radius: 4px;
        }

        .selectedInventory {
          background-color: #4da47a;
          color: white;
        }

        .dontTrackSelected {
          background-color: #b71c1c;
          color: white;
        }
      }
    }
  }

  .OOSVisibilityContainer {

    margin-bottom: 28px;

    .header {
      margin-bottom: 15px
    }

    .actionButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .buttonStyle {
        border: 1px solid #4da47a;
        margin-right: 15px;
        padding: 3px;
        border-radius: 3px;


        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          cursor: pointer;
        }

        .stockVisibility {
          padding: 7px 15px;
          font-size: 15px;
          border-radius: 3px;
        }

        .orderOnOOS {
          padding: 7px 15px;
          font-size: 15px;
          border-radius: 3px;
        }

        .selected {
          background-color: #4da47a;
          color: white;
        }
      }
    }
  }

  .orderOnOOSContainer {
    margin-bottom: 0px;
  }

  .resetModalContainer {
    .resetHeader {
      color: red
    }
  }
}

#bulkInventoryResetModal {
  max-width: 350px;

  .resetHeader {
    color: black;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 30px;
  }

  .actionButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .buttonStyle {
      font-size: 16px;
      color: #4da47a;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#BulkInventoryQuantityModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;

  .header {
    display: flex;
    align-self: flex-start;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .quantityWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    .minusContainer {
      height: 38px;
      margin-right: 12px;
      border: 1px solid #b71c1c;
      padding: 2px 15px;
      font-size: 28px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }

    .quantityInputContainer {
      border: 1px solid #4da47a;
      padding: 2px;
      margin-right: 10px;
      width: 50px;
      height: 38px;
      border-radius: 4px;

      .quantityContainer {
        width: 87%;
        height: 100%;
        text-align: center;
        padding-left: 7px;
        font-size: 16px;
      }
    }

    .plusContainer {
      height: 38px;
      margin-right: 15px;
      border: 1px solid #4da47a;
      padding: 2px 16px;
      font-size: 15px;
      border-radius: 4px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .buttonCotnainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .cancelButton {
      font-size: 17px;
      color: #b71c1c;

      &:hover {
        cursor: pointer;
      }
    }

    .acceptButton {
      font-size: 17px;
      color: #4da47a;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
