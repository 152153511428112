.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px
}

.errorContainer .resellErrorClose {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 10px;
  display: flex;
  align-self: center;
}

.loaderContaine {
  padding: 50px;
  background-color: white;
}

.errorContainer .errorTile {
  margin-bottom: 15px;
}

#toast-container > div {
  opacity: 1;
  box-shadow: none;
}