.InquiriesScreenWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #232b36;
  height: 100%;;
}

.nothingSelectedContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #232B36;
  color: #68718b;
  font-size: 20px;
  border-right: 1px solid #000;
}