$seperator: 25px;

.edit-name {
  &.title {
    margin: 0px;
    margin-bottom: $seperator;
  }

  &.input-container {
    margin-bottom: $seperator;
    border: 1px solid black;
    border-radius: 10px;
    padding: 15px;
    .input-box {
      outline: none;
      border: none;
    }
  }

  &.button-container {
    text-align: right;
    .button-plain {
      border: none;
      background-color: transparent;
      font-size: 0.9rem;
      color: #59c18f;
      font-weight: bold;
    }
  }
}
