.grtContainer {
  width: 372px;
  border-radius: 4px;
  background-color: #FFFFFF;
  justify-content: center;
  max-height: 90vh;
  height: 100%;
  overflow: hidden;
}

.grtShowroomTitle {
  color: #000000;
  font-size: 24px;
  font-weight: 900;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}

.selectAllShowrooms {
  display: flex;
  align-items: center;
  margin-top: 14px;
  padding-left: 16px;
  padding-bottom: 10px;
}

.grtCheckbox {
  align-items: center;
  display: inherit;
  padding-right: 19px;
  margin-right: 16px;
}

.rows {
  display: flex;
  padding-left: 36px;
  flex-direction: row;
  padding-right: 36px;
}

.showroomMeta {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.grtShowroomEmailList {
  position: absolute;
  overflow: auto;
  left: 0;
  right: 0;
  bottom: 20px;
  top: 94px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}

.showroomName {
  color: #000000;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  padding-top: 10px;
}

.line {

}

.showroomEmailId {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  padding-bottom: 10px;
}

.selectedEmailIds {
  height: 46px;
  border-radius: 4px;
  background-color: #2F9644;
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  right: 20px;
  flex-direction: inherit;
  align-items: center;
}

.selectedCount {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 900;
  margin-left: 14px;
}

.selectAllShowrooms .selectAll {
  padding-left: 34px;
  cursor: pointer;
}

.clearSelection {
  cursor: pointer;
  margin-right: 25px;
}

.selectAllCheckbox {
}

.forward {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 900;
  margin-right: 14px;
}

.grtShowroomEmailList .rowContainer {
  cursor: pointer;
}

.grtShowroomEmailList .rowContainer:hover {
  background-color: #E2EBE4;
}

.rightArrow {
  height: 13px;
  width: 24px;
}

.emptySpace {
  flex: 1
}

.selectedEmailIds:hover {
  background-color: #256E25;
  cursor: pointer;
}

.loadingEmailList{
  margin-left: 170px;
  margin-right: 170px;
  margin-top: 50px;
}

.grtHeaderContainer{
  display: flex;
  justify-content: space-between;
}