.renderHeader {
  color: #8DF0C1;
  padding: 20px 22px 22px 32px;
  font-size: 20px;
}

.contactOrderLoaderContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.AutoSizerList {
  height: calc(100vh - 100px)
}

.AutoSizerList:focus {
  outline: none;
}
