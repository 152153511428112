.language-section {
  .language-section-link {
    text-decoration: none;

    &:hover {
      background-color: #0d131b;
    }
  }

  .text-template-share {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
    background: transparent;
    color: white;
    font-size: 0.9rem;
  }
}

.language-section-modal {
  &.language-modal-title {
    margin-bottom: 10px;
    margin-top: 0px;
  }

  &.language-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    .language-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: none;
      background: transparent;
      border-bottom: 1px solid #ddd;
      padding: 15px 0px;
      font-size: 1rem;
    }

    .language-text--selected {
      font-weight: 700;
    }

    .selected-text {
      color: #59c18f;
    }
  }

  &.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button-plain {
      border: none;
      background-color: transparent;
      font-size: 0.9rem;
      padding: 0px;
    }

    .button-submit {
      color: #59c18f;
      font-weight: 700;
    }
  }
}
