.settings-data-container {
  background-color: #242c36;
  padding: 0px 30px;

  &:hover {
    background-color: #0d131b;
  }

  &.settings-data--click {
    cursor: pointer;
  }

  .settings-data-row-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #3a424e;
    padding: 15px 0px;

    .icon-container {
      flex-shrink: 0;

      & > svg {
        fill: white;
        width: 20px;
      }
    }

    .settings-data-text-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: start;
      text-align: left;
      padding-right: 10px;
      overflow: hidden;

      .settings-data-text--title {
        color: white;
        margin: 0px;
        margin-bottom: 10px;
        font-size: 1.1rem;
      }

      .settings-data-text--description {
        color: #9caabd;
        text-align: left;
        font-size: 0.9rem;
        max-width: 100%;
      }
    }
  }
}
