.featureLimitMainContainer {
  background-image: linear-gradient(to bottom, #4D5E77, rgba(0,9,21,89));
  padding: 25px;
  border-radius: 8px;
  line-height: 1.4;

  .icon {
    margin: 0px 0px 15px -10px;

    .image {
      width: 40px;
      height: 40px;
    }
  }

  .title {
    color: white;
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: bold
  }

  .description {
    color: white;
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 1.3;
  }

  .contactUs {
    color: white;
    background-image: linear-gradient(to bottom,  #6e90ff, #4a64f0);
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .arrowRight {
      margin-top: 4px;
      width: 20px;
    }
  }
}
