.plans-billing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;

  & > img {
    width: 100px;
  }

  .plans-billing-section {
    background-color: #323b47;
    width: 400px;
    border-radius: 10px;
    padding: 15px;

    .plans-billing-section-title {
      color: #89efa4;
      margin: 0px;
      margin-bottom: 5px;
    }

    .plans-billing-data-container {
      display: flex;
      flex-direction: column;
      padding: 10px 0px;
      border-bottom: 1px solid white;

      .plans-billing-data-title {
        color: #8291a4;
        font-size: 0.9rem;
        margin-bottom: 5px;
      }

      .plans-billing-data-value {
        font-size: 1.1rem;
      }

      .plans-billing-data-usage {
        border: none;
        background-color: transparent;
        font-size: 0.9rem;
        color: #61b8d5;
        line-height: 1.1rem;
        text-decoration: underline;
        text-align: left;
        padding: 0px;
      }
    }
  }
}
