/* .ContactOrdersWrapper {
  display: flex;
  flex-direction: column;
} */

.OrdersContactWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #232B36;
  border-right: 1px solid #000;
  position: relative;
}