.OrderHeaderContainer {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 15px 22px;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  
  .leftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    .selectAllContainer{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 5px;
    }

    .selectAll {
      color: white;
      padding-left: 10px;
      padding-top: 2px;

      &:hover {
        cursor: pointer;
        color: #57dd9c;
      }
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    .downloadIconContainer {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
      
      .downloadIcon {
        width: 22px;
        height: 22px;
        fill: white;

        &:hover {
          cursor: pointer;
          fill: #57dd9c;
        }
      }
    }

    .deleteIconContainer {
      .deleteIcon {
        width: 20px;
        height: 20px;
        fill: white;
        
        &:hover {
          cursor: pointer;
          fill: #57dd9c
        }
      }
    }
  }
}