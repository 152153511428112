.inquiryMultiSelectHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px 20px 30px;
  
  .leftContainer {
    display: flex;
    flex-direction: row;

    .selcectAll {
      color: white;
      padding-left: 10px
    }
  }

  .rightContainer {
    .deleteIconContainer {
      
      .deleteIcon {
        width: 20px;
        height: 20px;
        fill: white;
  
        &:hover {
          fill: #57dd9c;
          cursor: pointer;
        }
      }
    
    }
  }
}