.settingsList {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #121923;
  border-right: 1px solid black;
  width: 50%;

  .settings-row-container {
    &:hover {
      background-color: #0d131b;
    }

    &.selected-setting {
      background: #090b10;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .singleRow {
    color: white;
    padding: 20px 0px;
    margin: 0px 27px;
  }

  .logout-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    color: white;
    text-align: center;

    .logout-button {
      border: none;
      border-radius: 5px;
      color: white;
      background-color: #f54c4c;
      padding: 10px 30px;
      font-size: 1rem;
    }
  }

  .settings-app-version {
    margin: 20px;
    color: #586577;
    font-size: 12px;
    text-align: center;
  }
}
