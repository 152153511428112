.RenderSingleOrderContainer {
  background-color: #191f26;
  border-radius: 6px;
  margin: 18px;
  padding: 15px 15px 13px 15px;
  width: 92%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
  border: none;
}

.RenderSingleOrderContainer:hover {
  background-color: #0f151d;
}

.bgContactOrder {
  background-color: #0f151d;
}

.RenderSingleOrderContainer .order-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.RenderSingleOrderContainer .leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.RenderSingleOrderContainer .confirmed-container {
  border: 1px solid #59C18F;
  border-radius: 20px;
  padding: 5px 10px;
  color: #59C18F;
}

.RenderSingleOrderContainer .numberOfNew {
  background-color: #CD4040;
  border-radius: 20px;
  padding: 3px 7px;
  font-size: 12px;
  white-space: nowrap;
  margin-left: 10px;
}

.leftContainer .deletedVisitorContainer {
  margin-bottom: 6px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
}

.leftContainer .deletedVisitorContainer .icon-image {
  width: 19p;
  height: 19px;
}

.leftContainer .deleted {
  color: #DB5354;
  font-size: 12px;
  margin-bottom: 3px;
}

.leftContainer .firRowContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.leftContainer .secondRowContainer {
  color: #9098AD;
  font-size: 13px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.secondRowContainer .time {
  margin-right: 10px;
}

.secondRowContainer .daysAgo {
  margin-right: 10px;
}

.secondRowContainer .catalogueName  {
  color: #59C18F;
}

.leftContainer .imageItems {
  align-self: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.imageItems .item-details-container {
  align-self: flex-start;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.imageItems .numberOfItems {
  font-size: 18px;
}

.imageItems .imageContainer {
  position: relative;
  height: 40px;
  width: 100px;
}

.pictureImage {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
