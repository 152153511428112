.container {
  width: 477px;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.sendToGrtShowroomTitle {
  display: flex;
  color: #000000;
  font-size: 22px;
  font-weight: 900;
  line-height: 30px;
  margin: 26px 26px 15px;
  text-align: center;
}

.message {
  display: flex;
  color: #000000;
  font-size: 22px;
  margin-right: 26px;
  margin-left: 26px;
  font-weight: 300;
  line-height: 30px;
}

.confirmationButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 26px;
  height: 30px;
  flex-direction: row;
}

.cancelButton {
  color: #FF4646;
  font-size: 22px;
  font-weight: 300;
  cursor: pointer;
}

.sendNowButton {
  color: #000000;
  font-size: 22px;
  cursor: pointer;
  font-weight: 900;
}

.loaderConfirmState {
  margin-left: 20px;
  margin-right: 20px;
}

.responseContainer {
  display: flex;
}

.responseSuccess {
  display: flex;
  color: #000000;
  margin: 26px 26px 15px;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
}

.responseFailed {
  display: flex;
  color: #DF4848;
  margin: 26px 26px 15px;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
}

.responseMessage {
  display: flex;
  color: #000000;
  font-size: 22px;
  font-weight: 300;
}

.responseButtonContainer {
  display: flex;
  margin: 20px 26px;
  justify-content: flex-end;
}

.okButton {
  color: #000000;
  font-size: 22px;
  cursor: pointer;
  font-weight: 900;
}

.sendNowButton:hover{
  color:#256E25;
}